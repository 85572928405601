import { Link } from "gatsby"
import React, { useState, useEffect, useRef } from "react"
import parse from 'html-react-parser'
import LottieWrap from '../components/lottie/lottieWrap';
import "../sass/pages/_services_archive.scss";
import { useInView } from 'react-intersection-observer';
import { graphql } from "gatsby"
import Seo from '../components/seo'
import { SchemaLinksToAbsolute } from "../helpers/SchemaLinksToAbsolute";
import { Helmet } from "react-helmet";

const Services = ({ data }) => {

  useEffect(() => {
    if(typeof document !== 'undefined'){
      document.querySelector('body').setAttribute('data-bg-color', 'bg-white')
      document.querySelector('body').setAttribute('data-class', '')
    }
  }, [])

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });

  return (
    <>
    {data.wpPage.seo.schema.raw !== undefined ? 
      <SchemaLinksToAbsolute schemaRaw={data.wpPage.seo.schema.raw} /> 
    : "" }
    <Helmet>
          <script type="application/ld+json">
            {
              `{
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [{
                  "@type": "ListItem",
                  "position": 1,
                  "name": "Home",
                  "item": "${process.env.GATSBY_SITE_URL}"
                  },{
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Services",
                    "item": "${process.env.GATSBY_SITE_URL}/services/"
                  },{
                }]
              }` 
            }
          </script>
        </Helmet>
    <Seo 
    title={data.wpPage.seo.title} 
    description={data.wpPage.seo.metaDesc} 
    date={data.wpPage.date}
    slug={'/'+data.wpPage.slug+'/'}
    robotsFollow={data.wpPage.seo.metaRobotsNofollow}
    robotsIndex={data.wpPage.seo.metaRobotsNoindex}
    canonicalUrl={data.wpPage.seo.canonical}
    />
    {/* {console.log('canonical', data.wpPage.seo.canonical)} */}
    {/* {console.log('slug', data.wpPage.slug)} */}

    {/* {console.log(data.wpPage.seo.canonical === `/${data.wpPage.slug}/`)} */}
    {/* {console.log(data.wpPage.seo.canonical,`/${data.wpPage.slug}/`)} */}
    {/* {console.log(typeof data.wpPage.seo.canonical, typeof `/${data.wpPage.slug}/`)} */}
     <div className="services-archive">
       <div className={`bg_shapes ${inView ? 'is_inview' : ''}`}></div>
        <div className="wrapper clearfix">
          <section className={`intro ${inView ? 'is_inview' : ''}`} ref={ref}>
              <h1>Our Services</h1>
              <div className="intro-text">
                  {parse(data.wpPage.acf_introText.introText)}
              </div>
          </section>
          <section className={`the_services-wrapper ${inView ? 'is_inview' : ''}`} ref={ref}>
            <ul className="the_services">
              {
                data.allWpService.nodes.map((service) => {
                  return(
                    <li 
                      id={service.acf_serviceFields.abbreviatedTitle.toLowerCase()+'_wrap'} 
                      data-target={service.acf_serviceFields.abbreviatedTitle.toLowerCase()}
       
                    >
                      <Link to={service.link} className={service.acf_serviceFields.abbreviatedTitle.toLowerCase() + " service"} >
                        <article>
                          <LottieWrap 
                              animation={service.acf_serviceFields.abbreviatedTitle.toLowerCase()} 
                              playOnload={true}
                            />
                          <div className="text-wrap">
                            <h2>{service.title}</h2>
                            <div className="text">{parse(service.excerpt)}</div>
                          </div>
                        </article>
                      </Link>
                    </li>		
                  )
                })
              }
            </ul>
          </section>
        </div>
     </div>
    </>
  )
}



export const query = graphql`
  {
    wpPage(slug: {eq: "services"}) {
      id
      acf_introText {
        introText
      }
      seo {
        title
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        canonical
        schema{
          raw
        }
      }
      slug
      date
    }
    allWpService(filter: {wpParent: {node: {slug: {eq: null}}}}, sort: {fields: order_services___order}) {
      nodes {
        excerpt
        title
        link
        slug
        acf_serviceFields {
          abbreviatedTitle
        }
        order_services {
          order
        }
      }
    }
  }
`

export default Services

